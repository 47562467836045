export const mappedLinks = [
  {
    "url": "https://www.partner-ads.com/dk/klikbanner.php?partnerid=21976&bannerid=41737",
    "target": "go/godream"
  },
  {
    "url": "https://www.awin1.com/cread.php?s=2789614&v=21375&q=404290&r=1423575",
    "target": "go/risskovbilferie"
  },
  {
    "url": "https://affiliate.salestring.com/aff_c?offer_id=498&aff_id=3152",
    "target": "go/smalldanishhotels"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fkro-ophold",
    "target": "go/norsmindekro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Ffemoe-kro",
    "target": "go/fem\u00f8kro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Ffilskov-kro",
    "target": "go/filskovkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fkro-ophold",
    "target": "go/molskroen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Ffredensborg-store-kro",
    "target": "go/fredensborgstorekro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fweekendophold",
    "target": "go/hvalpsundf\u00e6rgekro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fweekendophold",
    "target": "go/tambohuskrobadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fweekendophold",
    "target": "go/kongensbrokro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fkro-ophold",
    "target": "go/sallingsundf\u00e6rgekro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fspa-ophold",
    "target": "go/ystadsaltsj\u00f6bad"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fweekendophold",
    "target": "go/hotelthinggaard"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/fjordgaarden"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fweekendophold",
    "target": "go/hotelskansen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelbudersand"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fmarienlyst-strandhotel",
    "target": "go/marienlyststrandhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelkongarthur"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fsog%3Fq%3D%7B%2522location%2522%3A%7B%2522destination%2522%3A%7B%2522id%2522%3A31059001%2C%2522type%2522%3A%2522city%2522%7D%2C%2522type%2522%3A%2522autocomplete%2522%7D%2C%2522nights%2522%3A%5B2%2C3%5D%2C%2522startingDay%2522%3A%7B%2522type%2522%3A%2522month%2522%2C%2522month%2522%3A%25222024-12%2522%7D%2C%2522uuid%2522%3A%2522cc51bfde-3241-48a3-8673-482a21e0528b%2522%7D",
    "target": "go/kurhotelskodsborg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fspa-ophold",
    "target": "go/hotelviking"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fkragerup-gods",
    "target": "go/kragerupgods"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/villacopenhagen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Flaasby-kro",
    "target": "go/l\u00e5sbykro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fweekendophold",
    "target": "go/gerlevkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fschackenborg-slotskro",
    "target": "go/schackenborgslotskro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fkro-ophold",
    "target": "go/hovborgkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhotel-aarslev-kro",
    "target": "go/\u00e5rslevkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fweekendophold",
    "target": "go/r\u00f8dvigkrobadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fkro-ophold",
    "target": "go/rudb\u00f8lgr\u00e6nsekro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fden-gamle-graensekro",
    "target": "go/dengamlegr\u00e6nsekro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Flandgasthof-tarp",
    "target": "go/landgasthoftarp"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhotel-postgaarden-mariager",
    "target": "go/hotelpostgaardenmariager"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fkro-ophold",
    "target": "go/brom\u00f8llekro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/fr\u00f8slevkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/svostrupkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fkro-ophold",
    "target": "go/munkebokro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/n\u00f8rrevissingkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/nymindegabkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/sevelkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhotel-hjallerup-kro",
    "target": "go/hjallerupkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/agerskovkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fst-binderup-kro",
    "target": "go/stbinderupkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/sabrokro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/fjordkroen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/dengamledigegaard"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hj\u00f8rringkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/fladbrokro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhotel-menstrup-kro",
    "target": "go/menstrupkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/billumkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Frold-storkro",
    "target": "go/roldstorkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fvissenbjerg-storkro",
    "target": "go/vissenbjergstorkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fbenniksgaard-hotel",
    "target": "go/benniksgaardhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhotel-juelsminde-strand",
    "target": "go/hoteljuelsmindestrand"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelballumhus"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fweekendophold",
    "target": "go/hoteltroense"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhotel-dania",
    "target": "go/hoteldania"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fostergaards-hotel",
    "target": "go/\u00f8stergaardshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fpinenhus",
    "target": "go/hotelpinenhus"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhotel-limfjorden",
    "target": "go/hotellimfjorden"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelmarina"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhotel-soeparken",
    "target": "go/hotels\u00f8parken"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelkrybilykro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/purhuskro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Ftyrstrup-kro",
    "target": "go/tyrstrupkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/bovkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelkirstine"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fcomwell-klarskovgaard",
    "target": "go/comwellklarskovgaard"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelvinhuset"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelpandekagehuset"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/skjalmhvidehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhotel-hoejbysoe",
    "target": "go/hotelh\u00f8jbys\u00f8"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/torn\u00f8eshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/fjelstedskovhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/frederikvishotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fspa-ophold",
    "target": "go/kokkedalcastlecopenhagen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fspa-ophold",
    "target": "go/altermeierhof"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fslotsophold",
    "target": "go/dragsholmslot"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fweekendophold",
    "target": "go/hotelbretagne"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fwellness-ophold",
    "target": "go/strandhotelr\u00f8sn\u00e6s"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fweekendophold",
    "target": "go/comwellkongebrogaarden"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fweekendophold",
    "target": "go/munkebjerghotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fweekendophold",
    "target": "go/strandhotelletblokhus"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelamerika"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fspa-ophold",
    "target": "go/ruthshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fdronninglund-hotel",
    "target": "go/dronninglundslot"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hoteloasiaaarhus"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/tortuehamburg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/thethief"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/mayfairhoteltunneln"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/bestwesternmalm\u00f6arenahotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/elitehotelesplanade"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/clarionhotelmalm\u00f6live"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/elitehotelsavoy"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/qualityhotelview"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fspa-ophold",
    "target": "go/steigenbergeralsik"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fgolfophold",
    "target": "go/himmerland"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fspa-ophold",
    "target": "go/hotelkoldingfjord"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fspa-ophold",
    "target": "go/comwellkellerspark"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fweekendophold",
    "target": "go/comwellborupgaard"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhelnan-marselis-hotel",
    "target": "go/helnanmarselishotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/koldinghotelapartments"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelfaaborgfjord"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/clarionhotelcopenhagenairport"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/griffenspahotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelhesselet"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fslotsophold",
    "target": "go/hvedholmslotshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fcomwell-varbergs-kusthotell",
    "target": "go/varbergskusthotell"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hoteltyl\u00f6sand"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fslotsophold",
    "target": "go/storerestrupslotshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fstrandhotel-weissenhauser-strand",
    "target": "go/weissenhausgrandvillageresortspa"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fmaritim-strandhotel-travemunde",
    "target": "go/maritimstrandhoteltravem\u00fcnde"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/arosatravem\u00fcnde"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fslotsophold",
    "target": "go/sauntehusslotshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fslotsophold",
    "target": "go/sophiendalslotshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/vraaslotshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fslotsophold",
    "target": "go/kokkedalslotshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fweekendophold",
    "target": "go/skjolden\u00e6sholmhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhindsgavl-slot",
    "target": "go/hindsgavlslot"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/liselundnyslot"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/n\u00f8rrevosborg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fslotsophold",
    "target": "go/hotels\u00f8rupherregaard"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fsinatur-hotel-skarrildhus",
    "target": "go/skarrildhussinaturhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/haraldsk\u00e6rsinaturhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/strandhotelgl\u00fccksburg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Ffalkenberg-strandbad",
    "target": "go/falkenbergstrandbad"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/acbellasky"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhotel-sonderborg-strand",
    "target": "go/hotels\u00f8nderborgstrand"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotellmossbylund"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhotel-des-nordens",
    "target": "go/hoteldesnorden"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/cabinnesbjerg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/br\u00f8ndumshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/broholmslot"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/ascothotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/comwellkors\u00f8r"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/comwellmiddelfart"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fcomwell-arhus",
    "target": "go/comwellaarhus"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/copenhagenadmiralhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/havgaardenbadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/foldenshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/copenhagenmarriotthotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Ffangel-kro",
    "target": "go/fangelkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/gillelejebadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/flinchshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hjertingbadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hjorthsbadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelhafenflensborg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/h\u00f8jbykrohotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelodeon"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelchristianiv"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelhanseatischerhof"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/holtegaardbedbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelodense"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhotel-christiansminde",
    "target": "go/hotelchristiansminde"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelallinge"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelamfjord"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelpetit"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hvidesandehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelplazaodense"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelabildgaard"
  },
  {
    "url": "https://track.adtraction.com/t/t?a=1894128928&as=1927207364&t=2&tk=1&url=https://www.trivago.dk/da/srl/hotel-das-tegernsee?search=100-5505",
    "target": "go/dastegernsee"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/sidedesignhotelhamburg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelinger"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelplesner"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelfriheden"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelpr\u00e6stekilde"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelklippen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotel\u00e6r\u00f8"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelaltepostflensburg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelgarni"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelresidensm\u00f8n"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotell\u00f8kkenstrand"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelbirke"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Froede-kro",
    "target": "go/hotelr\u00f8dekro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelg\u00e4sslingen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelgillelejestrand"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelroyal"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/klintholmbedandbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelsandvighavn"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/lotusbbspa"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/klitterhushavsbadshotell"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/louiselundbedbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/klitrosenhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/lundshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/mallingkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/imperialhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/konventum"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/jantzenshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/millinghotelmini19"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/juhlsbedbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelritz"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/k\u00f8bmandsg\u00e5rdensbedandbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/langagergaardbb"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/millinghotelwindzor"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/justsleepbedandbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/lisebybedandbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotel\u00f8sterport"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/grandhotelstruer\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/n\u00e6sbydalebadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/l\u00f8kkenbadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhotel-tannishus",
    "target": "go/hoteltannishus"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelhornb\u00e6khus"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelvejlefjord"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/tivolihotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelnielsjuel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelskanderborghus"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/strandbybadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/r\u00f8nneshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fgl-brydegaard",
    "target": "go/glbrydegaard"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelskovly"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelnystedhavn"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/husetihejls"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/heltoftenbedbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/jasminvejbb"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/klintholmbedbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/m\u00f8n\u00f8kologiskbb"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/fjeldeguesthouse"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/mejrupbedbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/sengogkaffebb"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/svendborgrooms"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/oasensams\u00f8"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/danhostelodensecity"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/cam\u00f8nogaarden"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelhafenflensburg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/altedirektionsvillakupferm\u00fchle"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/radissonbluriversidehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/thelamphotelnorrkoping"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/nyn\u00e4shavsbad"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/lyngdalhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/sch\u00e6fferg\u00e5rden"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelskjern"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/skr\u00f8belevgods"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/ny\u00f8bjerggaardbedbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelansgar"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotellisboa"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hoteljutlandia"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/gjerrildkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/montrahotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelskandia"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/romantikhotelkielerkaufmann\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/montraskagahotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelsidesporet"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/korningkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelopus"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/comwellkolding"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/sk\u00e6b\u00e6kcentret"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/comwellrebildbakker"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/comwellsor\u00f8"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhotel-6400-sonderborg",
    "target": "go/hotel6400"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/tisvildelejestrandhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/vilconhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/71nyhavnhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/rye115hotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicpalacehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandiccopenhagen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/bestwesternplushoteleyde"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/golfhotelviborg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/sk\u00e6rb\u00e6kcentret"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hoteldgihusetherning"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelscheelsminde"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/sportshotelvejen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Floegstoer-parkhotel",
    "target": "go/l\u00f8gst\u00f8rparkhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/enjoyresortsr\u00f8m\u00f8"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fcomwell-hc-andersen-odense",
    "target": "go/comwellhcandersenodense"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelfalster"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/radissonredaarhus\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/villaprovence"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicaarhuscity\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelatlantic\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/pensionholmegaard\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/skovshovedhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/pegasusbedandbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/gudhjemvandrerhjem\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/badepensionatsandloppen\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/thelodge"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelgudhjem\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/sweethomebedbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/gr\u00f8nbechshotel\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/sophienbergslot"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/sandkaasbadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/ramadabywyndhamflensburg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/sonnerupgaardgods"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/radissonbluscandinaviacopenhagen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/solbjerggaardbedandbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/r\u00f8dk\u00e6rg\u00e5rd"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/strandgaardenbadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/phoenixcopenhagen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/sktpetri"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/rydhaveslotshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelbalkastrand\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/veders\u00f8klitbadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/thesquare"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/tiendegaardenbb"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/wakeupcopenhagenbernstorffsgade"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/tik\u00f8bbedbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/aalb\u00e6kglkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/aar\u00f8sundbadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/toscanarestaurantbedbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/troensebedandbreakfastbythesea"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelkolding"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hoteln\u00f8rrevinkel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/taastrupparkhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelhedegaarden"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/aggerbadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelvildbjerg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/vindeballekro\u00e6r\u00f8sk\u00f8bing"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/prinsenhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicglostrup"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicolympic"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicregina"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/bbholmeolstrup"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/sixtussinaturhotel\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicbygholmpark"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandichvidovre"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandickolding"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/firsthotelgrand\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicsydhavnen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicsluseholmen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandiceremitage"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/n\u00f8rherredhushotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicodense"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicringsted"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicroskilde"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicsilkeborg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/skipperkroen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandics\u00f8nderborg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicjacobgade"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicaalborg\u00f8st"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicaalborgcity"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicaarhusvest"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicthemayor"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/comwellroskilde"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fcomwell-holte",
    "target": "go/comwellholte"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/comwellk\u00f8gestrand"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/badehotels\u00f8fryd"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelvillabrinkly"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/quistgaardenbedbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/stevnsklintstrandpension"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelvillastrand"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/orbadensparesort"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/gullmarsstrandhotellkonferens"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/k\u00f6rundagolfkonferenshotell"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/ellerybeachhouse"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/dalecarliahotelspa"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotells\u00f6draberget"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/sanktj\u00f6rgenpark"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fmarstrands-havshotel",
    "target": "go/marstrandshavshotell"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fkrusenberg-herrgard",
    "target": "go/krusenbergherrg\u00e5rd"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/vannspahotelandconference"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/marholmen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhotell-hallstaberget",
    "target": "go/hotellhallstaberget"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/s\u00f6derk\u00f6pingsbrunn"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/strandhotelzingst"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/nykirstineberggods"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/stellamarishoteldeluxe"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fstorebaelt-sinatur-hotel",
    "target": "go/sinaturhotelstoreb\u00e6lt"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/skagenhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/rungstedgaard"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandiccphstrandpark"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelstrandlyst"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/naturperlen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/staybystage"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotels\u00f8nderstrand"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/faaborgbyferie"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/badehotelharmonien"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hennem\u00f8lle\u00e5badehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/lundsgaardgodsbadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelskovpavillonen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelsvanen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/kompashotelaalborg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/bestwesternhotelhebron"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/glostrupparkhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicfalkoner"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/moxycopenhagen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/refborghotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Ftonderhus",
    "target": "go/hotelt\u00f8nderhus"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelranders"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hoteldalgas"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fjorgensens-hotel",
    "target": "go/j\u00f8rgensenshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/cityhotelnattergalen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/glavern\u00e6ssinaturhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/cabinnodense"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/blommenslystkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fulvsby-herrgard",
    "target": "go/ulvsbyherrg\u00e5rd"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/zamekkliczk\u00f3w"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/nimbhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhotel-bramslevgaard",
    "target": "go/hotelbramslevgaard"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelschlossneustadtglewe"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/sdrommekro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelferdinand"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/ibsenshotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/comwellhvidehusaalborg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelottiliabybr\u00f8chnerhotels"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicfront\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/skepparholmennacka"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelriverton"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/treetopspahangouthotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/sunlighthotelconferenceandspa"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fmossebergs-kurort",
    "target": "go/kurortenm\u00f6sseberg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/qualityhotelthebox"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hoteloresund"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/kostabodaarthotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/\u00e5kerbladsg\u00e4stgiverihotellspa"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fgrand-hotel-saltsjobaden",
    "target": "go/grandhotelsaltsj\u00f6baden"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/varbergsstadshotellasiaspa"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/steamhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/arkenhotelartgardenspa"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotellg\u00e4stis"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelneptun"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/easthotelhamburg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/grandhyattberlin"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/gastwerkhotelhamburg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/scandicweber\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/themadisonhotelhamburg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/lindnerparkhotelhagenbeck"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/atlantichotelkiel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelzugbr\u00fcckegrenzau"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/theritzcarltonberlin"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/radissonbluhotelfrankfurt"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/empireriversidehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/atlantichotelsailcity"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/centrovital"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/althoffgrandhotelschlossbensberg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/radissonblusenatorhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelringk\u00f8bing"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelfr\u00f6s\u00f6park\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/castelbrando"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelgolfchateaudechailly \u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/althoffhotelf\u00fcrstenhof\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/fermedelaran\u00e7onni\u00e8re\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelvillafiorita\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelschlossneustadt-glewe\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hoteljagdschlossletzlingen\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/chateaudelaballuere"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/chateaudesarpentis\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/manoirdelapoterie&spa\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/\u00f6ren\u00e4sslott\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/b\u00e4ckaskogslott\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/\u00e4ngavallen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/schlossfleesensee\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/schlosshotelburgschlitz\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelschlossneetzow\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/schlossbasthorst\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/romantischeshotelmenzhausen\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/dengamlearrest\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/skovdalkro\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/kal\u00f8vigbadehotel\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelkl\u00f8veres"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/feriep\u00e5toppen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/skagenstrandhotelferiecenter"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelmarie"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/skagenmotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelstrandlyskagen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/motelh\u00f8jm\u00f8llekro\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/feriestedetnordliv"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/danhostelaarhuscity"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/cabinnaarhushotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelfaber"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelguldsmedenaarhus"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/\u00f8lstedkrohotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelnor"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelstrandparken"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/millinghotels\u00f8park"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelpejseg\u00e5rden"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/nyborgstrandhotelkonference"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/kildeg\u00e5rden"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/keiserg\u00e5rden"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fhundested-hotel",
    "target": "go/hundestedkrohotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/drag\u00f8rbadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/detgamleapotekbb"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Fhoteller%2Fgram-slot-and-slotskroen",
    "target": "go/gramslotskro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/godstedlundbb"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/fuglsangherregaard"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hermanbangbedbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/herl\u00f8vkrohotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/femh\u00f8j"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/frederiksdalsinaturhotelkonference"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/cphstudiohotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/helenekildebadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/fakkelgaarden"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/copenhagenislandhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/engholmbb"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/foldensbedandbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/asminder\u00f8dkro"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/ask\u00f8mejeribedandbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/bythebridgebedandbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/byensbedandbreakfastnakskov"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/bbbytheseahirtshals"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/amaliebedandbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/bellingegaardbedandbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/birkelygaardbedandbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/bandholmhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/colorhotelskagen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/birkendebedandbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/alsklosterbedbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/ballenbadehotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/albertebedbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/ballenanneks"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/cocobedandbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/bakkelundbedandbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/bestwesternplushotelsvendborg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/bakkegaardm\u00f8nsklint"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelknudsensgaard\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/radissonblupapirfabrikkenhotel\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/bestwesternplushotelkronjylland\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hiltonviennaplaza\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelgrandferdinandvienna\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelkommand\u00f8rg\u00e5rden\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/enjoyresortsmarinafisken\u00e6s\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/paladshotel\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/p\u00e5torvet\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelxenia\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/detgamler\u00e5dhus"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelschaumburg\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/bestwesternroyalholstebro\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelolelundsgaard\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/parkinnbyradissonl\u00fcbeck\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/parkhotelamlindenplatz\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/theniurig\u200b"
  },
  {
    "url": "https://track.adtraction.com/t/t?a=1894128928&as=1927207364&t=2&tk=1&url=https://www.trivago.dk/da/srl/g%C3%A6stehus-auning-kro-r%C3%B8nde?search=100-4699944",
    "target": "go/auningkro\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/jerlevkro\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/poppelgaardenromobb"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/cabinnvejle\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelfalken\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/h\u00f8jslevkro\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/landals\u00f8h\u00f8jlandet"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/landalgr\u00f8nh\u00f8jstrand"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/firstcamphasmarkstrand"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/landalfyrklit"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/ferieparksalztalparadies"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/lalandiaresortr\u00f8dby"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/lalandiaresortbillund"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/feriecenterskagenstrand"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/vandervalkresortlinstow"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/gardahotelfortecharme"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelmedi"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/landalseawest"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/landalr\u00f8nbjerg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/landalmiddelfart"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/firstcampskovlundcampingcottages"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/firstcampbogensestrandcampingcottages"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/firstcampb\u00f8s\u00f8restrandferiepark"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/landalebeltoft"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/udsigtenmarstal"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/ebeltoftparkhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/danhostelebeltoft"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hoteldagmar\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelph\u00f8nix"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelcity"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotellolland"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/nykirstineberggodsbedbreakfast"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/danhostelsaksk\u00f8bing"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelresidensm\u00f8en"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelfrederiksv\u00e6rk"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/r\u00f8rvigcentret"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/danhostelr\u00f8m\u00f8"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/skagenharbourhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/andershotelwalsrode"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/nordseehotelhinrichsen"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/amberecontelberlincharlottenburg"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/bestwesternhotelhohenzollern"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/vejlecenterhotel"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelvarde"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/millinghotelplaza\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelhedemarken\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/gottsk\u00e4rhotell\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/firsthotelchristianiv"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/lydingeresort\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotellundia\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/bestwesternvalhallparkhotell\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelcarlshamn\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/clarioncollectionhotelbristol\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotellwettern\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelvierjahreszeitenl\u00fcbeck\u200b"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelkaiserhof"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelalmondbusinessspa"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/hotelgrano"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/anantaranewyorkpalacebudapest"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/theritzcarltonbudapest"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/continentalhotelbudapest"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/corinthiabudapest"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/mysteryhotelbudapest"
  },
  {
    "url": "https://www.awin1.com/cread.php?awinmid=21375&awinaffid=1423575&ued=https%3A%2F%2Fwww.risskov-bilferie.dk%2Ftemaer%2Fophold",
    "target": "go/h\u00f4telch\u00e2teaudemontvillargenne"
  }
]
