import React, { useEffect, useMemo } from "react"
import Image from "gatsby-image"
import { FaMapMarkerAlt, FaLongArrowAltRight } from "react-icons/fa"
import Stars from "../guideComponents/Stars"
import { graphql, useStaticQuery } from "gatsby"
import { filterHotelIdFromTitle } from "../../helperFunctions/filterHotelIdFromTitle"
import {mappedLinks} from "../../data/hotels/mappedLinks"

const Hotel = ({ hotel }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativePath: {
            in: ["badges/godt-til-prisen.png", "badges/personlig-favorit.png"]
          }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const realLink = useMemo(() => {
    const found = mappedLinks.find(link =>
       hotel.link.includes(link.target)
    )
    return found?.url ?? hotel.link;
  }, [hotel.link]);

  useEffect(() => {
    if (window.adlab) {
      window.adlab.updateLinks();
    }
  }, [realLink])


  return (
    <section
      className={`grid grid-cols-1 md:grid-cols-2-3 bg-white my-xl rounded shadow-md gap-lg relative`}
    >
      {hotel.bestPrice && (
        <div className="absolute h-24 w-24 -mt-5 -ml-5 top-0 left-0 z-10">
          <Image fluid={data.allFile.edges[1].node.childImageSharp.fluid} />
        </div>
      )}
      {hotel.favorite && (
        <div className="absolute h-24 w-24 -mt-5 -ml-5 top-0 left-0 z-10">
          <Image fluid={data.allFile.edges[0].node.childImageSharp.fluid} />
        </div>
      )}
      {/* insert hotel image */}
      <article
        className={`hotelImgWrapper h-full overflow-hidden relative ${
          hotel.classes ? hotel.classes : ""
        }`}
      >
        <a href={realLink} target="_blank" rel="noopener noreferrer nofollow">
          <Image
            fluid={hotel.image.childImageSharp.fluid}
            className="min-w-full min-h-full rounded-tl rounded-bl hotel-image"
            alt={hotel.name}
          />
        </a>
      </article>
      <article className="p-lg">
        <h3 className="font-bold">{filterHotelIdFromTitle(hotel.name)}</h3>
        <Stars numOfStars={hotel.stars} />
        <p className="flex items-center my-sm">
          <FaMapMarkerAlt className="fill-red mr-2 text-sm" /> {hotel.location}
        </p>
        <p className="mt-md">{hotel.description}</p>
        <p className="font-semibold italic mt-md">
          Laveste pris for dobbeltværelse i skrivende stund: {hotel.price} DKK
          for 2 personer
        </p>
        <a
          href={realLink}
          target="_blank"
          rel="noopener noreferrer nofollow"
          className="bg-green hover:bg-hoverGreen text-white py-md px-lg text-center inline-flex items-center uppercase mt-md"
        >
          Reserver / Læs mere <FaLongArrowAltRight className="ml-2" />
        </a>
      </article>
    </section>
  )
}

export default Hotel
